.table thead th {
    border-top: 0 !important;
    border-bottom: 1px solid rgba(0, 0, 0, .12) !important;
    font-weight: 500;
    background: rgb(230, 235, 247) !important;
}

.table th,
.table td {
    border-top: 0 !important;
    border-bottom: 1px solid rgba(0, 0, 0, .12);
    font-size: 12px;
}

.styled-select select {
    -moz-appearance: none;
    /* Firefox */
    -webkit-appearance: none;
    /* Safari and Chrome */
    appearance: none;
}

.select-pagination-row {
    cursor: pointer;
    height: 24px;
    min-width: 24px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding-left: 8px !important;
    padding-right: 12px !important;
    box-sizing: content-box;
    font-size: inherit;
    color: inherit;
    border: none;
    background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    direction: ltr;
}

.pagination-label {
    margin: 0 4px;
}

.test {
    position: relative;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    font-size: inherit;
    color: inherit;
    margin-top: 1px;
}

.test>svg {
    top: 0;
    right: 0;
    color: inherit;
    position: absolute;
    fill: currentColor;
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
}