
html, body, #root {
    height: 100%;
}

body {
  margin: 0;
  font-family: 'Poppins', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.menu-link {
    color: #292e448c !important;
}

.menu-link:hover {
    color: #292E44 !important;
    background-color: transparent !important;
}

.btn-eye {
    color: #4e545a !important;
}

.btn-eye:hover {
    color: #212529 !important;
}

.nav-link.disabled {
    opacity: .5;
}

.slick-prev:before,
.slick-next:before {
    color: #292e44 !important;
}

.fill {
    padding: 10px;
    overflow: hidden;
}

.content-promo {
    position: relative;
    display: flex !important;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 12px;
}

.content-promo img {
    margin-left: 11px;
    flex-shrink: 0;
    width: 100%;
    object-fit: contain;
    min-height: 100%;
}

.custom .custom-file-label::after {
    background-color: #6c8aa8;
}

.card {
    border: 0 !important;
    -webkit-box-shadow: 0px 20px 23px -20px #3a3a3a1f;
    box-shadow: 0px 20px 23px -20px #3a3a3a1f;
}

.list-group-item.active {
    z-index: 2;
    color: #495057 !important;
    background-color: #FFF !important;
    border-left: 4px solid #9F2D5D !important;
    padding-left: 16px;
}

.card-border-left {
    flex: 0 0 8.3333333333%;
    min-width: 2.333333% !important;
    max-width: 2.333333% !important;
}

.card-cstm-content {
    flex: 0 0 66.6666666667%;
    min-width: 72.666667% !important;
    max-width: 73.666667% !important;
}

.swal2-timer-progress-bar {
    background: #9f2d5d !important;
}

.vertical-timeline {
    padding: 15px 0 !important;
}

.vertical-timeline::before {
    background: #facce2 !important;
}

.vertical-timeline-element-content{
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    border-radius: 0px !important;
    padding: 0px !important;
}

.vertical-timeline-element-icon {
    /* -webkit-box-shadow: none !important;
    box-shadow: none !important; */
    background-color: #9f2d5d;
    width: 20px !important;
    height: 20px !important;
    top: 20px !important;
    left: 10px !important;
}

.vertical-timeline-element-content p {
    margin: .5em 0 0 !important;
}

.vertical-timeline-element-content-arrow {
    border: 0px !important
}

@media (min-width: 768px) {
    .card-border-left {
        flex: 0 0 8.3333333333%;
        min-width: 2.333333% !important;
        max-width: 2.333333% !important;
    }

    .card-cstm-content {
        flex: 0 0 66.6666666667%;
        min-width: 72.666667% !important;
        max-width: 73.666667% !important;
    }

    .fill {
        height: 270px;
    }

    .content-promo {
        height: 250px;
    }
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 900px !important;
        margin: 1.75rem auto;
    }
}

@media (min-width: 800px){
    .filter-tabs{
        position: absolute;
        top: 0;
        right: 0;
    }
}

@media (max-width: 800px) {
    .tab-content{
        margin-top: 100px;
    }
    .filter-tabs {
        position: absolute;
        top: 50px;
        z-index: 100;
    }
}

@media (max-width: 320px){
    .revenue-tab .nav-link{
        font-size: 14px;
    }
}

@media (max-width: 767px) and (min-width: 320px) {
    .fill {
        height: 170px;
    }

    .content-promo {
        height: 150px;
    }

    .alert-heading {
        font-size: 1.25rem !important;
    }

    .alert-cstm p {
        font-size: 14px !important;
    }
}
