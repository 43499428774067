body.sheet-open{
    overflow: hidden;
    padding-right: 17px;
}
.sheet-container.exit{
    opacity: 0;
    pointer-events: none;
}
.sheet-container.enter-done{
    opacity: 1;
    pointer-events: visible;

}
.sheet-container{
    transition: all 0.3s ease-in-out;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    pointer-events: none;
    z-index: 100;
}
.sheet-overlay{
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, .4);
}
.sheet{
    overflow-y: auto;
    overflow-x: hidden;
    position: absolute;
    background-color: #fff;
    box-shadow: 0 0 20px -10px rgba(0, 0, 0, .5);
    transition: all 0.3s ease-in-out;
}
.sheet.right{
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    right: 0;
    width: 50%;
    height: 100%;
}
.sheet.left{
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    left: 0;
    width: 50%;
    height: 100%;
}
.sheet.bottom{
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    bottom: 0;
    width: 100%;
    height: 50%;
}
.sheet.top{
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    top: 0;
    width: 100%;
    height: 50%;
}
.sheet-container.enter-done .bottom{
    bottom: 0px;
}
.sheet-container.exit .bottom, .sheet-container .bottom{
    bottom: -200px;
}
.sheet-container.enter-done .right{
    right: 0px;
}
.sheet-container.exit .right, .sheet-container .right{
    right: -400px;
}
.sheet-header{
    background-color: #fff;
    padding:10px 15px;
    font-size: 18px;
    border-bottom:1px solid #e7e8eb;
}
.sheet-body{
    padding:15px;
}
.sheet-close{
    position:absolute;
    right: 15px;
    top: 5px
}
.sheet-body{
    padding:15px;
}
.btn:hover{
    /* background-color: #f5f6f8; */
}