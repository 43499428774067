.badge{
    font-size:14px !important;
    padding:8px 20px !important;
}
.badge-warning {
    background: rgba(230, 122, 31, 0.5) !important;
    color: #8f4a10 !important;
  }
  
  .badge-danger {
    background: rgba(220, 53, 69, 0.5) !important;
    color: #921925 !important;
  }
  
  .badge-primary {
    background: rgba(159, 45, 93, 0.5) !important;
    color: #50172f !important;
  }
  
  .badge-success {
    background: rgba(24, 188, 156, 0.5) !important;
    color: #0c6251 !important;
  }
  
  .badge-dark {
    background: rgba(41, 46, 68, 0.5) !important;
    color: #030304 !important;
  }
  
  .badge-info {
    background: rgba(23, 162, 184, 0.5) !important;
    color: #0c525d !important;
  }
  
  .badge-light {
    background: rgba(248, 249, 250, 0.5) !important;
    color: #292E44 !important;
  }
  .badge-secondary {
    background: rgba(166, 169, 172, 0.5) !important;
    color: #292E44 !important;
  }