.admin-header {
    position: relative;
    z-index: 99;
    top: 0;
    right: 0;
    left: 0;
    height: 65px;
    background: #fff;
    box-shadow: none;
    align-items: center;
}

.dropdown-menu.show{
    transform: translate(-50px, 68px) !important;
}

.avatar {
    position: relative;
    display: inline-block;
}

.avatar {
    width: 3rem;
    height: 3rem;
}

.avatar-title {
    display: flex;
    width: 70%;
    height: 70%;
    margin: 5px;
    color: #fff;
    background-color: #b1c2d9;
    align-items: center;
    justify-content: center;
}

.dropdown-toggle::after{
    border-top: 0.3em solid #333333 !important;
}

.dropdown-item.active,
.dropdown-item:active {
    color: rgba(41, 46, 68, 1) !important;
    background-color: #d4d9e4 !important;
}

element.style {
    position: absolute;
    will-change: transform;
    top: 0px;
    left: 0px;
    transform: translate3d(-162px, 66px, 0px);
}

.dropdown-menu.show {
    display: block;
}

.dropdown-menu[x-placement^=top],
.dropdown-menu[x-placement^=right],
.dropdown-menu[x-placement^=bottom],
.dropdown-menu[x-placement^=left] {
    right: auto;
    bottom: auto;
}

.dropdown-menu-right {
    right: 0;
    left: auto;
}

.dropdown-menu {
    top: 10px !important;
    box-shadow: 0px 4px 15px #D0DEF5;
}

.triangle-right::before {
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    content: "";
    border-bottom: 12px solid white;
    position: absolute;
    top: -10px;
    right: calc(50% - 12px);
}

.triangle-left::before {
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    content: "";
    border-bottom: 12px solid white;
    position: absolute;
    top: -10px;
    left: 24px;
}

.menu-link:hover {
    background-color: #dfe2e6;
}
.menu-link{
    transition: background-color .3s;
}
.menu-link.active{
    font-weight: 500;
    color: #000 !important;
}
.no-arrow::after{
    visibility: hidden !important;
}