input:focus,textarea:focus, select:focus, input:active,textarea:active, select:active{
    outline:none;
    border:0px
}
.custom-form {
    position: relative;
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
}

.custom-label {
    font-weight: 500;
    color: #85888e;
    position: absolute;
    top: 0.5rem;
    left: 0.75rem;
    margin-bottom: 0px;
    transition: .3s;
}
.custom-form.active label {
    top: -1rem;
    font-size: 10px;
    left: 0;
}
.custom-form.active .custom-border-bottom {
    display: inline-block;
    width: 5px;
    height: calc(100%);
    background: #A02D60;
    position: absolute;
    bottom: .3px;
    left: 0;
    animation: heightSlide .5s;
    animation-direction: normal;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}

@keyframes heightSlide {
    from {
        height: 0%;
        right: 50%;
        opacity: 0;
    }
    to {
        height: calc(100%);
        opacity: 1;
        right: 0.12rem;
    }
}
